import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {


  connect() {
  	let hasAccessToAllCheckbox = $('.js-has-access-to-all-nationalities');
  	let nationalitiesContainer = $('.js-nationalities-container');
  	let hasAccessToAll;

  	hasAccessToAllCheckbox.on('change', function(){
  		hasAccessToAll = $(this).is(":checked");
  		
  		if(hasAccessToAll){
  			nationalitiesContainer.find('input').prop('disabled', true);
  			nationalitiesContainer.addClass('display-none');
  		}else{
  			nationalitiesContainer.find('input').prop('disabled', false);
  			nationalitiesContainer.removeClass('display-none');
  		}	
  	});

  	hasAccessToAllCheckbox.trigger('change');
  }	

}