import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["start", "end"];

  connect() {
    this.startPicker = flatpickr(this.startTarget, {
      onClose: this.closeStart.bind(this),
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: 'Y-m-d'
    });

    this.endPicker = flatpickr(this.endTarget, {
      onClose: this.closeEnd.bind(this),
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: 'Y-m-d'
    });
  }

  closeStart(selectedDates, dateStr, instance) {
    this.endPicker.set("minDate", dateStr);
  }

  closeEnd(selectedDates, dateStr, instance) {
    this.startPicker.set("maxDate", dateStr);
  }
}