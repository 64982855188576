import flatpickr from "flatpickr";
import { Controller } from "@hotwired/stimulus"
import moment from 'moment'

export default class extends Controller {
  connect() {
  	if(this.element._flatpickr !== undefined){
  		return;
  	}

    let flatpickrLocale = { firstDayOfWeek: 1 }; // Monday

 //   let openDates = $(this.element).data('open-dates');
    let openDates = this.element.dataset.openDates;
    let today = new Date();

    let flatpickrOptions = {
        locale: flatpickrLocale,
        disableMobile: true,
        altInput: true,
        altFormat: "d/m/Y",
        dateFormat: 'Y-m-d',
    };

    if(openDates !== undefined){
      // flatpickrOptions.minDate = timeSlotOptions.min_date;
      // flatpickrOptions.maxDate =  timeSlotOptions.max_date;

      flatpickrOptions.onDayCreate = function(dObj, dStr, fp, dayElem) {
        let date = moment(dayElem.dateObj).format('YYYY-MM-DD');

        if(openDates.includes(date)){
          if(dayElem.dateObj < today){
            dayElem.className += " selectable past-date"
          }else {
            dayElem.className += " selectable"
          }
        }else{
          dayElem.className += " flatpickr-disabled";
        }
      }
    }

    flatpickr(this.element, flatpickrOptions);
    
  }

  teardown() {
    if(this.element._flatpickr !== undefined){
    	this.element._flatpickr.destroy();
    }
  }


  disconnect() {
  }
}


